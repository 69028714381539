import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';
import { withLayout } from '../../components/layout.tsx';
import { Container, Icon, Header, Table } from 'semantic-ui-react';

export const Simulations: FunctionComponent<{}> = ({}) => {
  return (
    <Container fluid textAlign="center">
      <Header as="h1">Simulations with Salvus</Header>
    </Container>
  );
};

export default withLayout(Simulations);
